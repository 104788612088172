import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ProspectInputService } from 'src/app/services/api/prospect-input-service.service';
import { Router } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators,  } from '@angular/forms';
import { ErrorAlertService } from 'src/app/common-ui/error-alert/error-alert.service';
import { HttpClient } from '@angular/common/http';
import { ValidatorService } from 'src/app/services/validator.service';


@Component({
  selector: 'cif-input',
  templateUrl: './cif-input.component.html',
  styleUrls: ['./cif-input.component.scss'],
})


export class CifInputComponent implements OnInit {
  @Input() lobCd= "";
  currentLobCd: string = ''
  prospectForm: FormGroup;
  baseKey: string = 'agent-flow.prospect-complete-container';
  customerNumberControlName: string = "";
  inputRequiredFlag = false;
  errorFlag: boolean = false;
  title: string = '';
  fpdCustomerNumber = "fpdCustomerNumber";
  wccCustomerNumber = "wccCustomerNumber";
  customerNumber = "customerNumber";

  constructor(
    private prospectInputService: ProspectInputService,
    private router: Router,
    private errorAlertService: ErrorAlertService,
    private httpClient: HttpClient,
    private validatorService: ValidatorService,
  ) {
   }

  ngOnInit(): void {
    this.customerNumberControlName = this.lobCd.toLowerCase() == "fpd" ? this.fpdCustomerNumber : this.lobCd.toLowerCase() == "wcc" ? this.wccCustomerNumber : this.customerNumber;
    this.prospectForm = new FormGroup({
      [this.customerNumberControlName]: new FormControl(null, [
        Validators.required,
        this.validatorService.customerNumberValidator(this.lobCd),
      ]),
    });
  }

  onSubmitCustomerNumber() {
    if (this.errorAlertService.errorsCount > 0) {
      this.inputRequiredFlag = true;
      this.errorFlag = false;
      this.errorAlertService.submit();
      this.prospectForm.markAllAsTouched();
      return;
    }
    const val = this.lobCd.toLowerCase() == "fpd" ? this.prospectForm.controls[this.fpdCustomerNumber].value : this.lobCd.toLowerCase() == "wcc" ?
      this.prospectForm.controls[this.wccCustomerNumber].value : this.prospectForm.controls[this.customerNumber].value;

    this.prospectInputService
      .captureConsentData(val)
      .subscribe((responseData) => {
        if(responseData.body.nextStateName == "UNEXPECTED_SYSTEM_FAILURE"){
          this.errorFlag = true;
          this.title = 'agent-flow.prospect-complete-container.error-body-copy';
        }

        if(responseData.body.nextStateName == "CIF_MATCHING_FAILURE"){
          this.errorFlag = true;
          this.title = 'agent-flow.error-messages.customerNumber-mismatch';
        }

        if(responseData.body.nextStateName == "ON_BOARDING_COMPLETE"){
          this.router.navigateByUrl('/success');
        }
      },
       (responseData) => {
          this.errorFlag = true;
          this.title = 'agent-flow.prospect-complete-container.error-body-copy';
      });
  }

}
