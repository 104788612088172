import { isNull } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { Moment } from 'moment'
import { ValidatorFn, FormControl, AbstractControl, Validator, FormArray, FormGroup, ValidationErrors, Form } from '@angular/forms';

import * as moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  exactMatchValidator(emailControl: FormControl,emailConfirmControl: FormControl): ValidatorFn {
    return (): ValidationErrors | null => {
      if (emailControl.value && emailConfirmControl.value){
        if (emailControl.value !== emailConfirmControl.value) {
          emailConfirmControl.setErrors({'pattern': true});
          return null;
        }

        if (emailControl.value === emailConfirmControl.value) {
          emailConfirmControl.setErrors(null);
          return null;
        }
      }

      return null;
    };
  }

  postalCodeValidator(province: AbstractControl, postalCode: AbstractControl): ValidatorFn {
    return ():  ValidationErrors | null => {
        const txtPostalCode = postalCode.value || '';
        const txtProvince = province.value || '';
        const POSTAL_CODE = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]\d[ABCEGHJ-NPRSTV-Z]\d$/;
        const provincePatterns = {
            AB: /^[Tt]{1}/gm,
            BC: /^[Vv]{1}/gm,
            MB: /^[Rr]{1}/gm,
            NB: /^[Ee]{1}/gm,
            NL: /^[Aa]{1}/gm,
            NT: /^[Xx]{1}/gm,
            NU: /^[Xx]{1}/gm,
            NS: /^[Bb]{1}/gm,
            ON: /^[KLMNPklmnp]{1}/gm,
            PE: /^[Cc]{1}/gm,
            QC: /^[GHJghj]{1}/gm,
            SK: /^[Ss]{1}/gm,
            YT: /^[Yy]{1}/gm,
            '': /[^]*/gm
        };

      /*
        * This is triggered whenever the Province value is changed and if
        * a Postal code value was provided but it doesn't match any of the province patterns that's on our list then the 'notInProvince' error is activated
        * OR
        * no Postal code value was provided then an error is dislayed, this error informs the user that a Postal code is required
        * OR
        * a Postal code value was provided and it matches one of the province patterns on our list then all the postal code error messages are erased/deleted
        */
        if (province.value ){

          if (!provincePatterns[province.value].test(txtPostalCode) && (txtPostalCode !== "")){
            postalCode.setErrors({ 'notInProvince': true });
          }
          else if (!provincePatterns[province.value].test(txtPostalCode) && (txtPostalCode === "")){
            postalCode.setErrors({ 'required': true });
          }
          else if(!POSTAL_CODE.test(txtPostalCode)){
            postalCode.setErrors({ 'pattern': true });
          }
          else if (provincePatterns[province.value].test(txtPostalCode)){
            postalCode.setErrors(null);
          }

          return null;
        }

        /**
         * All postal code errors are deleted/erased whenever the user goes from selecting a specific Province to selecting NO Province
         */
        if ((txtProvince === '') && postalCode.errors && postalCode.errors.notInProvince){
          postalCode.setErrors(null);
        }

        return null;
    };
  }

  /**
       * The validation is to check if
       * full address including street address, space, unit number, space, unit type exceeds the maximum 40 characters limit.
       * OR
       * street number exceeds the maximum 7 charachters limit.
       * OR
       * street name exceeds the maximum 28 charachters limit.
       *
       * Inline error message must be displayed under street address
       * if user leaves street address or unit type or unit number
       *
       */
   streetAddressLengthValidator(unitTypeControl, unitNumberControl): ValidatorFn {
    return (control: FormControl):  ValidationErrors | null => {
        const streetNumberLength = 7;
        const streetNameLength = 28;
        const maxLength = 40;
        if (control.value) {
            const streetNumber = control.value.replace(/(\d+)( )?(.+)/g, '$1');
            const streetName = control.value.replace(/(\d+)( )?(.+)/g, '$3');
            const streetAddress = (control.value + ' ' +
                (unitTypeControl.value || '') + ' ' + (unitNumberControl.value || '')).replace(/( )(?= *\1)/g, '').trim();
            if (streetAddress.length > maxLength || streetNumber.length > streetNumberLength || streetName.length > streetNameLength) {
                return { maxlength: {value: control.value} };
            }
        }
        return null;
    };
  }

  dateValidator(requireBefore: boolean = true): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
          if (control.value) {
                const date = moment(control.value,"MM/DD/YYYY");
                const today = moment();
                if(requireBefore) {
                  if (date.isAfter(today)) {
                    return { 'invalidDate': true }
                  }
                } else {
                  if (date.isBefore(today)) {
                    return { 'invalidDate': true }
                  }
                }
              }
              return null;
      };
    }

//Customer Number Validation 
  customerNumberValidator(lobCd: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
   if (control.value){
     let regexPattern: string;
     switch (lobCd.toLowerCase()){
      //for 8-digit validation
       case "fpd":
         regexPattern = '^[0-9]{8}$';
         break;
      //for 7-digit alphanumeric validation   
       case "wcc":
         regexPattern = '^[A-Za-z0-9]{7}$';
         break; 
       default:
         //default case for all other LOB's
         regexPattern = '^[0-9]{9}$';
       break;
     }
    const verify = this.validateRegex(regexPattern,control.value);
    if(!verify){
      return{'pattern':regexPattern};
    }
   }
   return null;
 };
}  
validateRegex(pattern, value): boolean {
    const reg = new RegExp(pattern);
    const verify = reg.test(value) ? true : false;
    return verify;
}
}
