<div class="td-row">
  <div
    class="
      td-col-xs-12
      td-col-sm-10
      td-col-sm-offset-1
      td-col-md-8
      td-col-md-offset-2
    "
  >
    <section>
      <div>
        <h1 class="text-center" tabindex="-1">
          {{
            "agent-flow.prospect-complete-container.primary-header" | translate
          }}
        </h1>
      </div>
    </section>

    <div *ngIf="successfulMatch">
      <app-validation-success-alert></app-validation-success-alert>
      <cif-input [lobCd]="currentLobCd"></cif-input>
    </div>

    <div *ngIf="!successfulMatch">
      <app-validation-unsuccessful-alert></app-validation-unsuccessful-alert>
      <app-unsuccessful-match-instructions></app-unsuccessful-match-instructions>
    </div>

    <br />

    <div class="td-row dotted-divider"></div>

    <h2 class="text-center">
      {{
        "agent-flow.prospect-complete-container.personal-info-header"
          | translate
      }}
    </h2>
    <form #postForm="ngForm">
      <div>
        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="givenName">
                  {{
                    "agent-flow.prospect-complete-container.firstName-label"
                      | translate
                  }}</label
                >
                <output id="givenName" [textContent]="prospectInput?.givenName"></output>
              </div>
            </div>
          </div>
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="middleName">{{
                  "agent-flow.prospect-complete-container.middleName-label"
                    | translate
                }}</label>
                <output id="middleName" [textContent]="prospectInput?.middleName"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="lastName">{{
                  "agent-flow.prospect-complete-container.lastName-label"
                    | translate
                }}</label>
                <output id="lastName" [textContent]="prospectInput?.familyName"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="DateofBirth">{{
                  "agent-flow.prospect-complete-container.dateOfBirth-label"
                    | translate
                }}</label>
                <output id="DateofBirth" [textContent]="prospectInput?.birthDt"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="mobilePhone">{{
                  "agent-flow.prospect-complete-container.mobilePhoneNumber-label"
                    | translate
                }}</label>
                <output id="mobilePhone" [textContent]="prospectInput?.telephoneNum"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="SecondaryPhone">{{
                  "agent-flow.prospect-complete-container.secondaryPhoneNumber-label"
                    | translate
                }}</label>
                <output id="SecondaryPhone" [textContent]="prospectInput?.secondaryTelephoneNum"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="emailAddress">{{
                  "agent-flow.prospect-complete-container.emailAddress-label"
                    | translate
                }}</label>
                <output id="emailAddress" [textContent]="prospectInput?.emailAddress"></output>
              </div>
            </div>
          </div>
        </div>

        <br />

        <section class="td-divider">
          <div class="td-sm-full-bleed">
            <div class="td-row">
              <div class="td-col-xs-12">
                <hr class="td-thin-divider-line-1" />
              </div>
            </div>
          </div>
        </section>

        <h2 class="text-center">Address</h2>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="streetNum">{{
                  "agent-flow.prospect-complete-container.streetNumber-label"
                    | translate
                }}</label>
                <output id="streetNum" [textContent]="prospectInput?.streetNum"></output>
              </div>
            </div>
          </div>
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="unitNum">{{
                  "agent-flow.prospect-complete-container.unitNumber-label"
                    | translate
                }}</label>
                <output id="unitNum" [textContent]="prospectInput?.unitNum"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="streetName">{{
                  "agent-flow.prospect-complete-container.streetName-label"
                    | translate
                }}</label>
                <output id="streetName" [textContent]="prospectInput?.streetName"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="unitTypeCd">{{
                  "agent-flow.prospect-complete-container.unitType-label"
                    | translate
                }}</label>
                <output id="unitTypeCd" [textContent]="prospectInput?.unitTypeCd"></output>
              </div>
            </div>
          </div>
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="city">{{
                  "agent-flow.prospect-complete-container.city-label"
                    | translate
                }}</label>
                <output id="city" [textContent]="prospectInput?.cityName"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="territoryCd">{{
                  "agent-flow.prospect-complete-container.province-label"
                    | translate
                }}</label>
                <output id="territoryCd" [textContent]="prospectInput?.territoryCd"></output>
              </div>
            </div>
          </div>
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="postalCodeNum">{{
                  "agent-flow.prospect-complete-container.postalCode-label"
                    | translate
                }}</label>
                <output id="postalCodeNum" [textContent]="prospectInput?.postalCodeNum"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="country">{{
                  "agent-flow.prospect-complete-container.country-label"
                    | translate
                }}</label>
                <output id="country">Canada</output>
              </div>
            </div>
          </div>
        </div>

        <br />

        <section class="td-divider">
          <div class="td-sm-full-bleed">
            <div class="td-row">
              <div class="td-col-xs-12">
                <hr class="td-thin-divider-line-1" />
              </div>
            </div>
          </div>
        </section>

        <h2 class="text-center">
          {{
            "agent-flow.prospect-complete-container.interacService-primary-header"
              | translate
          }}
        </h2>

        <h3>
          {{
            "agent-flow.prospect-complete-container.creditBureau-secondary-header"
              | translate
          }}
        </h3>

        <div *ngIf="successfulMatch">
          <section>
            <div *ngIf="!singleSourceCB">
              <div class="matching-results-failed-panel">
                <p class="text-center">
                  <b>{{
                    "agent-flow.prospect-complete-container.response-body-copy"
                      | translate
                  }}</b>
                </p>
              </div>
            </div>
            <div class="matching-results-panel" *ngIf="singleSourceCB">
              <app-credit-bureau-information></app-credit-bureau-information>
            </div>
          </section>
        </div>

        <div *ngIf="!successfulMatch">
          <section>
            <div class="matching-results-failed-panel">
              <p class="text-center">
                <b>{{
                  "agent-flow.prospect-complete-container.response-body-copy"
                    | translate
                }}</b>
              </p>
            </div>
          </section>
        </div>

        <h3>
          {{
            "agent-flow.prospect-complete-container.otherFi-secondary-header"
              | translate
          }}
        </h3>

        <div *ngIf="successfulMatch">
          <section>
              <div class="matching-results-failed-panel" *ngIf="!singleSourceOFI">
                <p class="text-center">
                  <b>{{
                    "agent-flow.prospect-complete-container.response-body-copy"
                      | translate
                  }}</b>
                </p>
              </div>
            <div class="matching-results-panel" *ngIf="singleSourceOFI">
              <app-fi-information></app-fi-information>
            </div>
          </section>
        </div>

        <div *ngIf="!successfulMatch">
          <section>
            <div class="matching-results-failed-panel">
              <p class="text-center">
                <b>{{
                  "agent-flow.prospect-complete-container.response-body-copy"
                    | translate
                }}</b>
              </p>
            </div>
          </section>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-offset-4 td-col-sm-4">
            <div class="btn-container">
              <div *ngIf="successfulMatch">
                <a
                  class="td-link-inline cancel-button"
                  href="javascript:void(0)"
                  id="btnCancel"
                  allowunload="true"
                  (click)="openCancelModal()"
                  >{{
                    "agent-flow.prospect-complete-container.cancelBtn-label"
                      | translate
                  }}</a
                >
              </div>
             <div *ngIf="!successfulMatch">
                <a class="td-link-inline cancel-button"
                   href="javascript:void(0)"
                   id="btnCancel"
                  (click)="openCancelModal()"
                   allowunload="true">{{
                "agent-flow.prospect-complete-container.cancelBtn-label" | translate
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
